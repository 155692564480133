<template>
    <div class="posts_background">
        <Posts :error="error" :posts="posts" :api="api"/>
    </div>
</template>

<script>
    import Posts from '../components/Posts'

    export default {
        name: 'PostList',
        components: {
            Posts,
        },
        data () {
            return {
                posts: [],
                error: null,
                api: process.env.VUE_APP_ALTERED_ROOT_API,
                headers: {'Content-Type': 'application/json'}
            }
        },
        methods: {
            parseJSON: function (resp) {
            return (resp.json ? resp.json() : resp);
            },
            checkStatus: function (resp) {
            if (resp.status >= 200 && resp.status < 300) {
                return resp;
            }
            return this.parseJSON(resp).then((resp) => {
                throw resp;
            });
            }
        },
        async mounted () {
            try {
            const response = await fetch(this.api+'/posts?_sort=id:DESC', {
                method: 'GET',
                headers: this.headers,
            }).then(this.checkStatus)
                .then(this.parseJSON);
                this.posts = response
            } catch (error) {
              this.error = error
            }
        }
    }



</script>

<style scoped>
.posts-content{
    background-image: url("../assets/altered_background.jpg");
}
</style>