<template>
    <div v-if="error">
        <NotFound />
    </div>
    <div v-else class="posts-content">
        <b-card-group>
            <div class="posts-entry" v-for="post in posts" :key="post.id">
                    <b-card no-body class="overflow-hidden rounded-0" style="max-width:400px;">
                        <b-link :to="{
                            name: 'PostDetails',
                            params: {id: post.id}
                            }" class="text-reset text-decoration-none">
                            <b-row no-gutters>
                                <b-col lg="">
                                    <b-card-img-lazy :src="api + post.thumbnail.url" alt="Image" class="rounded-0" align="left"></b-card-img-lazy>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="">
                                    <b-card-body>
                                        <b-card-title style="text-align:left; font-weight:800;">{{post.title}}</b-card-title>
                                        <!--<div class="author-container" v-for="author in post.authors" :key="author.id">
                                            <b-card-text class="author-name">{{author.firstName}} {{author.secondName}}</b-card-text>
                                        </div>-->
                                        <div class="thumbnail-text">
                                            <b-card-text>{{post.thumbnailText}}</b-card-text>
                                        </div>
                                    </b-card-body>
                                </b-col>
                            </b-row>
                        <!--<b-link :to="{
                            name: 'PostDetails',
                            params: {link: post.link, posts: posts},
                        }" class="readmore text-reset text-decoration-none">
                            <b-row class="readmore-row">
                                <b-col lg="" class="readmore-col">
                                        <b-card-text>Read More</b-card-text>
                                        <b-icon icon="arrow-right" class="ml-auto" font-scale="1.5"></b-icon>
                                </b-col>
                            </b-row>
                        </b-link>-->
                        </b-link>
                    </b-card>
            </div>
        </b-card-group>
    </div>
</template>

<script>
    import NotFound from '@/components/NotFound'


    export default {
        name: 'Posts',
        components: {
            NotFound
        },
        props: {
            posts: Array,
            error: NotFound,
            api: String 
        },
        async mounted () {
        }
    }


</script>

<style scoped>
.posts-content{
    background-color:#ffffff;
}

.card-group{
    margin: 0 auto 0 auto;
}

.card{
    border: none;
}

.card-body{
    background-color: #f1f1f1;
}

.readmore-row{
    padding: 0 15px 0 15px;
    background-color:#ffffff;
}

.readmore-row:hover{
    background-color:#f1f1f1;
    transition: all 125ms linear;
}

.readmore-col{
    padding: 0.5em 1.25em;
    text-align: left;
}

.readmore-col p{
    display: inline-block;
    width: 90%;
    margin-bottom: 0;
}

.readmore-col svg{
    display: inline-block;
}

.card-deck .card{
    margin:0;
}

@media screen and (min-width: 800px) {
    .posts-entry{
        max-width: 25vw;
        margin: 1em auto 1em auto;
    }

    .card-group{
        width: 80vw;
        padding: 10vh 0 8vh 0;
        min-height: 100vh;
    }
}

@media screen and (min-width: 700px) and (max-width:799px) {
    .posts-entry{
        max-width: 45vw;
        margin: 1em auto 1em auto;
    }
    .card-group{
        width: 95vw;
        padding: 10vh 0 8vh 0;
        min-height: 100vh;
    }
}

@media screen and (max-width: 699px) {
    .card-group{
        max-width: 95vw;
        padding: 12vh 0 0 0;
        margin: 0 auto 0 auto;
        min-height: 100vh;
    }

    .posts-entry{
        margin: 1em 0 1em 0;
    }
  }
</style>